<template>
  <div class="receipt-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ $t('multiples.receipt.other') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok(records)">
          <ul v-if="records.length > 0" class="list-group m-b-lg">

            <li v-for="(record, index) in records" class="list-group-item">
              <b-row>
                <b-col>
                  <h4>
                    <font-awesome-icon icon="file-image"/>
                    {{ $t("multiples.receipt.one") + " " + (index + 1) }}
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5>{{ $t('common.filename') }}</h5>
                  {{ record.filename }}
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5>{{ $t('receipts.role') }}</h5>
                  {{ record.role_description }}
                </b-col>

                <b-col>
                  <h5>{{ $t('receipts.price') }}</h5>
                  {{ record.price | formatPrice }}{{ $t('unit.euro') }}
                </b-col>

                <b-col>
                  <h5>{{ $t('common.uploaded_at') }}</h5>
                  {{ record.created_at | formatDateDay }}
                </b-col>
              </b-row>
            </li>

          </ul>

          <ul v-if="newRecords.length > 0" class="list-group m-b-lg">

            <li v-for="(record, index) in newRecords" class="list-group-item">
              <b-row>
                <b-col>
                  <h4>
                    <font-awesome-icon icon="file-image"/>
                    {{ $t("multiples.receipt.one") + " " + (records.length + index + 1) }}
                  </h4>
                </b-col>
                <b-col>
                  <b-button @click.prevent="$delete(newRecords, index)" variant="no-outline"
                            style="float: right;">
                    <font-awesome-icon icon="times"/>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-for="input in inputListForRecord(record)" v-bind="$compute(input.col)"
                       v-if="input.visible===undefined || input.visible()">
                  <generic-input v-bind="input" v-model="record[input.key]"/>
                </b-col>
              </b-row>
            </li>

          </ul>

          <generic-input v-if="hasTypeCreate" type="file" multiple :accept="acceptedFileFormats" @input="addNewRecord"
                         max-file-size="25000000">
            <div class="input-file-box">
              {{ $t("receipts.add") }}<br>
              <font-awesome-icon icon="upload" class="m-t"/>
            </div>
          </generic-input>

          <hr v-if="hasRecords" class="m-t-lg m-b-lg">
          <div v-if="hasTypeCreate">
            <small>{{ $t('receipts.legal_text') }}</small>
            <br>
            <small>{{ $t('receipts.additional_infos_text') }}
              <b-link :to="{ name: 'terms' }" target="_blank">{{ $t("common.terms_of_use") }}</b-link>
            </small>
          </div>
          <div v-else>
            <p>{{ $t('receipts.upload_expired') }}</p>
          </div>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
          <b-button size="sm"
                    variant="primary"
                    type="submit" @click.prevent="$refs.form.requestSubmit()"
                    v-if="hasTypeCreate">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  getReceipts, getSelectableReceiptRoles, postReceipts,
} from "@/services/receipts";
import SearchableSelect from "@/components/searchable_select";
import CurrencyInput from "@/components/currency_input";
import GenericInput from "@/components/generic_input";
import {EventBus} from "@/eventbus";

export default {
  name: "ReceiptModal",
  components: {GenericInput, CurrencyInput, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1
    },
    type: {
      type: [String, Function],
      default: 'create'
    }
  },
  data() {
    return {
      acceptedFileFormats: ["jpeg", "jpg", "png"],
      visible: false,
      receiptRoles: [],
      records: [],
      newRecords: []
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        this.receiptRoles = await getSelectableReceiptRoles({})
        if (this.value) {
          if (this.id > 0) {
            this.records = await getReceipts(this.id, {}) // TODO: catch
          }
          this.newRecords.splice(0) // clear data
        }
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    },
    newRecords: {
      deep: true,
      handler(value) {
        value.forEach((record) => {
          record.unit = this.getReceiptRoleConfig(record.role)?.unit
        })
      }
    }
  },
  methods: {
    inputListForRecord(record) {
      let config = this.getReceiptRoleConfig(record.role)
      return [
        {
          key: 'role',
          label: 'receipts.role',
          placeholder: 'selector.select_receipt_role',
          type: 'select',
          fetchRecords: this.supportedReceiptRoles,
          col: { cols: 6 },
          required: true
        },
        {
          key: 'value',
          label: config?.label,
          type: 'number',
          min: 0,
          required: true,
          precision: () => config?.precision || 0,
          sign: () => config?.unit_label,
          visible: () => config !== undefined
        },
        {
          key: 'filename',
          label: 'common.filename',
          type: 'text',
          col: { cols: 12 },
          disabled: true
        }
      ]
    },
    getDefaultReceipt() {
      return {role: -1, value: 0, unit: undefined}
    },
    getReceiptRoleConfig(role) {
      return this.supportedReceiptRoles.find(t => t.id === role)
    },
    addNewRecord(file) {
      this.newRecords.push({...this.getDefaultReceipt(), file: file, filename: file.name})
    },
    async submitModal() {
      EventBus.$emit("toggleProgressSpinner", true)
      const recordsToUpload = [...this.newRecords]
      try {
        for (const record of recordsToUpload) {
          const base64 = await this.$parseFileToBase64(record.file)
          const data = {...record, filename: record.file.name, file_base64: base64}
          this.$delete(data, 'file') // remove file attribute for upload data (already included in base64)
          this.records.push(await postReceipts(this.id, data, {toggleProgressSpinner: false}))
          this.$delete(this.newRecords, this.newRecords.findIndex(item => item === record))
        }
      } catch(e) {}
      if (this.newRecords.length === 0) this.visible = false
      EventBus.$emit("toggleProgressSpinner", false)
    }
  },
  computed: {
    hasTypeCreate() {
      return this.$compute(this.type) === 'create'
    },
    supportedReceiptRoles() {
      return this.receiptRoles.filter(record => record.input_type === 'number')
    },
    hasRecords() {
      return this.records.length > 0 || this.newRecords.length > 0
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>