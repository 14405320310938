<template>
  <div class="transfer-book-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ $t('transfers.book_this_transfer') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok(record)">
          <ul class="list-group">
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("common.start_city") }}</h5>
                  <h4>
                    {{ record.start_shop.contact.name || record.start_shop.role_s }}<br>
                    <small>
                      {{ record.start_shop.street_name }}<br>
                      {{ record.start_shop.postcode }}, {{ record.start_shop.city.name }}
                    </small>
                  </h4>
                  <small>
                    <a class="" :href="record.route_link" target="_blank">
                      <font-awesome-icon icon="map-marker-alt"/>
                      {{ $t("transfers.on_a_map") }}
                    </a>
                  </small>
                </b-col>
                <b-col>
                  <h5>{{ $t("common.end_city") }}</h5>
                  <h4>
                    {{ record.end_shop.contact.name || record.end_shop.role_s }}<br>
                    <small>
                      {{ record.end_shop.street_name }}<br>
                      {{ record.end_shop.postcode }}, {{ record.end_shop.city.name }}
                    </small>
                  </h4>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("multiples.vehicle.one") }}</h5>
                  {{ record.vehicle.name }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.engine") }}</h5>
                  {{ record.engine_description }}
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("common.distance") }}</h5>
                  {{ record.distance | formatDistance }} {{ $t('unit.km') }}
                </b-col>
                <b-col>
                  <h5>{{ $t("transfers.duration") }}</h5>
                  {{ record.duration | formatDurationHours }} {{ $t('unit.hr') }}
                  {{ record.duration | formatDurationMinutes }} {{ $t('unit.min') }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.price") }}</h5>
                  {{ record.price_client | formatPrice }}{{ $t('unit.euro') }}
                </b-col>
                <b-col v-if="record.vehicle_class.retention > 0">
                  <h5>{{ $t("common.retention") }}</h5>
                  {{ record.vehicle_class.retention | formatPrice }}{{ $t('unit.euro') }}
                </b-col>
              </b-row>
            </li>
            <li v-if="record.hint" class="list-group-item">
              <h5>{{ $t("common.hint") }}</h5>
              <div v-html="$simpleFormat(record.hint)"></div>
            </li>
            <li v-if="record.notes" class="list-group-item">
              <h5>{{ $t("common.notes") }}</h5>
              {{ record.notes }}
            </li>
          </ul>

          <ul class="list-group">
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <generic-input type="select" v-model="dayId" label="common.pickup_day"
                                 placeholder="selector.select_day"
                                 required :fetchRecords="fetchDays"/>
                </b-col>
                <b-col>
                  <generic-input type="select" v-model="timeId" label="common.pickup_time"
                                 placeholder="selector.select_time" required
                                 :fetchRecords="times" :disabled="dayId <= 0"/>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item" v-if="showDriverSelect || showDriverPrice">
              <b-row>
                <b-col v-if="showDriverSelect">
                  <generic-input type="select" v-model="driver_id" label="selector.select_driver"
                                 placeholder="selector.select_no_driver" :fetchRecords="fetchDrivers"/>
                </b-col>
                <b-col v-if="showDriverPrice && driver_id > 0" cols="4">
                  <generic-input class="float-right" type="currency" v-model="driver_price"
                                 label="common.driver_price"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="record.requires_license_plate" class="list-group-item">
              <b-row>
                <b-col class="text-danger">
                  <generic-input type="checkbox" required v-model="accepted_requires_license_plate"
                                 title="transfers.service_requires_license_plate_title"
                                 label="transfers.service_requires_license_plate_label"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="record.requires_trailer_transport" class="list-group-item">
              <b-row>
                <b-col class="text-danger">
                  <generic-input type="checkbox" required v-model="accepted_requires_trailer_transport"
                                 title="transfers.service_requires_trailer_transport_title"
                                 label="transfers.service_requires_trailer_transport_label"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="hasSummerTiresInWinterSeason" class="list-group-item">
              <b-row>
                <b-col class="text-danger">
                  <generic-input type="checkbox" required v-model="accepted_summer_tires_in_winter_season"
                                 title="transfers.service_summer_tires_in_winter_season_title"
                                 label="transfers.service_summer_tires_in_winter_season_label"/>
                </b-col>
              </b-row>
            </li>
          </ul>

          <hr class="m-t-lg m-b-lg">
          <small>
            {{ $t('transfers.service_legal_text', {client_name: current_user.client.name}) }}
            <router-link :to="{name: 'penalties'}" target='_blank'>{{
                $t('transfers.service_legal_link')
              }}
            </router-link>
          </small>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
          <b-button size="sm"
                    variant="primary"
                    type="submit" @click.prevent="$refs.form.requestSubmit()"
                    :disabled="!isSaveButtonEnabled">
            {{ $t('button.book') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getSelectableDrivers} from "@/services/drivers";
import {getTransfer} from "@/services/transfers";
import {postReservation} from "@/services/reservations";
import SearchableSelect from "@/components/searchable_select";
import {mapGetters} from "vuex";
import GenericInput from "@/components/generic_input";

export default {
  name: "TransferBookModal",
  components: {GenericInput, SearchableSelect},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1
    },
    showDriverSelect: {
      type: [Boolean, Function],
      default: true
    },
    showDriverPrice: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      visible: false,
      record: {},
      dayId: 0,
      timeId: 0,
      driver_id: 0,
      driver_price: 0,
      accepted_requires_license_plate: false,
      accepted_requires_trailer_transport: false,
      accepted_summer_tires_in_winter_season: false,
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value && this.id > 0) this.record = await getTransfer(this.id) // TODO: catch
        this.dayId = 0
        this.accepted_requires_license_plate = false
        this.accepted_requires_trailer_transport = false
        this.accepted_summer_tires_in_winter_season = false
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    },
    dayId: {
      immediate: true,
      handler() {
        this.timeId = 0
      }
    }
  },
  methods: {
    async submitModal() {
      postReservation({
        transfer_id: this.id,
        date: this.date,
        driver_id: this.driver_id,
        driver_price: this.driver_price,
        price: this.record.price_client,
        accepted_requires_license_plate: this.accepted_requires_license_plate,
        accepted_requires_trailer_transport: this.accepted_requires_trailer_transport,
        accepted_summer_tires_in_winter_season: this.accepted_summer_tires_in_winter_season
      }).then((response) => {
        this.visible = false
      })
    },
    fetchDays() {
      let i = 0
      return this.record.booking_dates.map((entry) => {
        return {...entry, id: i += 1, text: this.$options.filters.formatDateDay(entry.date)}
      })
    },
    fetchDrivers(filterQuery) {
      return getSelectableDrivers(filterQuery)
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
    times() {
      if (this.dayId <= 0) return []
      let i = 0
      return this.fetchDays().find((record) => record.id === this.dayId).times.map((entry) => {
        let r = this.$options.filters.formatDateTime(entry[0])
        if (entry.length >= 2 && entry[0] !== entry[1]) r += " - " + this.$options.filters.formatDateTime(entry[1])
        return {id: i += 1, text: r, date: entry[entry.length - 1]}
      })
    },
    date() {
      if (this.dayId <= 0 || this.timeId <= 0) return null
      return this.times.find((record) => record.id === this.timeId)?.date
    },
    isSaveButtonEnabled() {
      return (!this.record.requires_license_plate || this.accepted_requires_license_plate) &&
          (!this.record.requires_trailer_transport || this.accepted_requires_trailer_transport) &&
          (!this.hasSummerTiresInWinterSeason || this.accepted_summer_tires_in_winter_season)
    },
    isWinterTireSeason() {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 9, 1); // October 1st
      const endDate = new Date(today.getFullYear() + 1, 3, 1); // April 1st next year
      return today >= startDate && today < endDate;
    },
    hasSummerTiresInWinterSeason() {
      return this.record?.tire === 2 && this.isWinterTireSeason
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>